import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import styled from "styled-components";

const VideoContainer = styled.div`
  position: relative;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  max-width: 853px;
  margin: 0 auto;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: lightgray;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background: #ff5c5c;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background: #e60000;
  }
`;

const OpenButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  margin-bottom: 10px;
  background-color: rgb(46, 48, 145);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: rgb(46, 48, 145);
  }
`;

const WatchOnYouTubeButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  margin-bottom: 10px;
  background-color: rgb(46, 48, 145);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: rgb(0, 112, 215);
  }
`;

const YoutubeEmbed = ({ embedId }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [player, setPlayer] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 870); // mobile size threshold
    };

    handleResize(); // Check initially
    window.addEventListener("resize", handleResize); // Update on resize

    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, []);

  const handleStopPlaying = () => {
    if (player) {
      player.pauseVideo(); // Pause the video
    }
    setIsVisible(false); // Hide the video container
    setIsCollapsed(true); // Collapse to the button
  };

  const handleOpenPlayer = () => {
    setIsCollapsed(false); // Show the player again
    setIsVisible(true); // Make sure the player is visible
  };

  const handlePlayerReady = (event) => {
    setPlayer(event.target); // Set the player instance
  };

  const opts = {
    height: "480",
    width: "853",
    playerVars: {
      autoplay: 0,
      modestbranding: 1, // Reduces YouTube branding
      rel: 0, // Disables showing related videos
    },
  };

  const onError = (event) => {
    console.error("YouTube embed error:", event.target.getVideoData());
  };

  return (
    <>
      {isMobile ? (
        <WatchOnYouTubeButton onClick={() => window.open(`https://www.youtube.com/watch?v=${embedId}`, '_blank')}>
          Watch on YouTube
        </WatchOnYouTubeButton>
      ) : isCollapsed ? (
        <OpenButton onClick={handleOpenPlayer}>Open Video</OpenButton>
      ) : (
        <VideoContainer isVisible={isVisible}>
          <CloseButton onClick={handleStopPlaying}>X</CloseButton>
          <YouTube
            videoId={embedId}
            opts={opts}
            onReady={handlePlayerReady} // Get the player instance
            error={onError}
          />
        </VideoContainer>
      )}
    </>
  );
};

export default YoutubeEmbed;
