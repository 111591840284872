import React, { useState } from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import DynamicModal from "./DynamicModal";
import tuffStud1 from "../assets/tuffstuds-1.jpg";
import tuffStud2 from "../assets/tuffstuds-2.jpg";
import dthDrillBits1 from "../assets/dth-drillbits-1.png";
import dthDrillBits2 from "../assets/dth-drillbits-2.png";
import dthHammer from "../assets/dth-hammer-1.svg";
import dthHammer2 from "../assets/dth-hammer-2.png";
import dthHammer4 from "../assets/dth-hammer-4.png";
import dthHammer5 from "../assets/dth-hammer-5.png";
import dthHammer6 from "../assets/dth-hammer-6.png";
import dthHammer7 from "../assets/dth-hammer-7.png";

// Array of products with images and their descriptions
const products = [
  {
    name: "DTH Hammers",
    icon: <img src={dthHammer} alt="DTH Hammer Icon" style={{ width: '150px', height: '150px' }}/>,
    description:
      "Mincon's DTH (down-the-hole) hammers are engineered to deliver outstanding drilling performance across all applications, while reducing energy consumption through the optimal use of available air. From construction and quarrying to mining and well drilling, Mincon's DTH hammers provide unmatched efficiency, reliability, and longevity.",
    images: [
      { src: dthHammer, description: "DTH Hammer Model 1" },
      { src: dthHammer2, description: "DTH Hammer Model 2" },
      { src: dthHammer4, description: "DTH Hammer Model 4" },
      { src: dthHammer5, description: "DTH Hammer Model 5" },
      { src: dthHammer6, description: "DTH Hammer Model 6" },
      { src: dthHammer7, description: "DTH Hammer Model 7" },
    ],
  },
  {
    name: "DTH Drill Bits",
    icon: <img src={dthDrillBits1} alt="DTH Drill Bits Icon" style={{ width: '150px', height: '150px' }}/>,
    description:
      "Precision-engineered DTH drill bits to complement your hammer and ensure smooth drilling operations.",
    images: [
      {
        src: dthDrillBits1,
        description:
          "Mincon offers one of the most comprehensive ranges of DTH drill bits in the industry, suited to all ground types and drilling conditions and for a wide variety of rock-drilling applications.",
      },
      {
        src: dthDrillBits2,
        description:
          "Mincon's DTH drill bits are available in three face designs – convex, concave, and flat – suited to the most common ground conditions for drilling.",
      },
    ],
  },
  {
    name: "Tuff Studs",
    icon: <img src={tuffStud2} alt="TuffstudsS Icon" style={{ width: '150px', height: '150px' }}/>,
    description:
      "Durable Tuff studs for enhanced performance and extended lifespan in tough drilling environments.",
    images: [
      { src: tuffStud1, description: "Tuff Stud Model 1" },
      { src: tuffStud2, description: "Tuff Stud Model 2" },
    ],
  },
];

const Products = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleCardClick = (product) => {
    setSelectedProduct(product);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
  };

  return (
    <Box sx={{ mb: 10, mt: 3, padding: "0 1rem 0 1rem" }}>
      <Typography
        variant="h4"
        gutterBottom
        textAlign="center"
        style={{
          padding: "1rem",
          color: "#FFFAF0",
          fontWeight: "bold",
          marginTop: "2rem",
          backgroundColor: "#2E3091",
          width: "calc(100% + 2rem)",
          marginLeft: "-1rem",
          marginRight: "-1rem",
        }}
      >
        Our Products
      </Typography>
      <Grid
        container
        spacing={1}
        wrap="wrap"
        sx={{
          justifyContent: "center",
          "@media (max-width: 599px)": {
            justifyContent: "flex-start",
            padding: "1rem",
          },
        }}
      >
        {products.map((product, index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                opacity: "0.99",
                justifyContent: "center",
                boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
                height: "300px",
                width: "100%",
                maxWidth: "600",
                cursor: "pointer",
                margin: "auto",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                },
              }}
              onClick={() => handleCardClick(product)}
            >
              <CardContent
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  overflow: "auto",
                  justifyContent: "center",
                  padding: ".5rem",
                  textAlign: "center",
                }}
              >
                {product.icon}
                <Typography
                  variant="h6"
                  sx={{
                    color: "rgb(46, 48, 145)",
                    fontWeight: "bold",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                    marginTop: "1rem",
                  }}
                >
                  {product.name}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Modal for product details */}
      {selectedProduct && (
        <DynamicModal
          open={!!selectedProduct}
          onClose={handleCloseModal}
          title={selectedProduct.name}
          description={selectedProduct.description}
          images={selectedProduct.images} // Pass the images array directly to the modal
        />
      )}
    </Box>
  );
};

export default Products;
