import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Reuse the styled button for modal actions
const StyledButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  margin-bottom: 10px;
  background-color: rgb(46, 48, 145);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: rgb(38, 40, 125);
  }
`;

const DynamicModal = ({ open, onClose, title, description, images }) => {
  const [isMobile, setIsMobile] = useState(false);

  // Check if images is defined and is an array before using its length
  const sliderSettings = {
    dots: true,
    infinite: Array.isArray(images) && images.length > 1, // Check if images is an array and has more than 1 item
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    centerMode: true, // Centers the image in the view
    centerPadding: "0", // Prevents padding on either side of the center image
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust breakpoint for mobile
    };
    handleResize(); // Initial call
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogContent
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: isMobile ? "#f5f5f5" : "white",
        }}
      >
        {/* Title */}
        <h2
          style={{
            color: "rgb(46, 48, 145)",
            marginBottom: "1rem",
            textAlign: "center",
          }}
        >
          {title}
        </h2>

        {/* Description */}
        <Typography
          variant="body1"
          style={{ marginBottom: "20px", textAlign: "center" }}
        >
          {description}
        </Typography>
        {/* Image Carousel */}
        {Array.isArray(images) && images.length > 0 ? (
          <Slider
            {...sliderSettings}
            style={{ width: "100%", marginBottom: "20px" }}
          >
            {images.map((image, index) => (
              <div key={index} style={{ textAlign: "center", padding: "10px" }}>
                <img
                  src={image.src}
                  alt={image.description || `Image ${index + 1}`}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "400px", // Keeps the image within reasonable size
                    objectFit: "contain", // Ensures the image isn't stretched
                    borderRadius: "5px", // Optional for rounded corners
                    margin: "0 auto", // Centers the image
                  }}
                />
                {image.description && (
                  <Typography
                    variant="body2"
                    sx={{ marginTop: "10px", textAlign: "center" }}
                  >
                    {image.description}
                  </Typography>
                )}
              </div>
            ))}
          </Slider>
        ) : (
          <Typography>No images available</Typography>
        )}
      </DialogContent>

      {/* Modal Actions */}
      <DialogActions
        style={{ display: "flex", justifyContent: "center", padding: "10px" }}
      >
        <StyledButton onClick={onClose}>Close</StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default DynamicModal;
