import React, { useState } from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import {
  StyledDrill,
  StyledSeaCan,
  StyledStorage,
  StyledDrillRig,
  StyledBulletdozerExcavator,
} from "../Custom-Icons/CustomIcons";
import DynamicModal from "./DynamicModal";
import seaCan1 from "../assets/sea-can1.jpg";
import seaCan2 from "../assets/sea-can2.jpg";
import seaCan3 from "../assets/sea-can3.jpg";
import seaCan4 from "../assets/sea-can4.jpg";
import seaCan5 from "../assets/sea-can5.jpg";
import seaCan9 from "../assets/sea-can9.jpg";
import seaCan10 from "../assets/sea-can10.jpg";
import bitSharpening from "../assets/bit-sharpening.png";

const services = [
  {
    name: "Bit sharpening",
    icon: <StyledDrill />,
    description:
      "Professional sharpening services to extend the life and efficiency of your drill bits.",
    images: [{ src: bitSharpening, description: "" }],
  },
  {
    name: "Drill Hammer Rebuilds",
    icon: <StyledDrillRig />,
    description:
      "Rebuilding and servicing drill hammers to ensure peak performance on-site.",
    images: [],
  },
  {
    name: "Sea Can Modifications",
    icon: <StyledSeaCan />,
    description:
      "Custom sea can modifications to meet storage and workspace requirements for job sites.",
    images: [
      { src: seaCan1, description: "" },
      { src: seaCan2, description: "" },
      { src: seaCan3, description: "" },
      { src: seaCan4, description: "" },
      { src: seaCan5, description: "" },
      { src: seaCan9, description: "" },
      { src: seaCan10, description: "" },
    ],
  },
  {
    name: "Indoor / Outdoor Storage",
    icon: <StyledStorage />,
    description:
      "Secure and flexible storage solutions for equipment and materials, both indoors and outdoors.",
    images: [],
  },
  {
    name: "Wear Services",
    icon: <StyledBulletdozerExcavator />,
    description:
      "Specialized wear services to extend the lifespan of heavy machinery components.",
    images: [],
  },
];

const Services = () => {
  const [selectedService, setSelectedService] = useState(null);

  const handleCardClick = (service) => {
    setSelectedService(service);
  };

  const handleCloseModal = () => {
    setSelectedService(null);
  };
  return (
    <Box sx={{ mb: 10, mt: 3, padding: "0 1rem 0 1rem" }}>
      <Typography
        variant="h4"
        gutterBottom
        textAlign="center"
        style={{
          padding: "1rem",
          color: "#FFFAF0",
          fontWeight: "bold",
          marginTop: "2rem",
          backgroundColor: "#2E3091",
          width: "calc(100% + 2rem)",
          marginLeft: "-1rem",
          marginRight: "-1rem",
        }}
      >
        Our Services
      </Typography>
      <Grid
        container
        spacing={1}
        wrap="wrap"
        sx={{
          justifyContent: "center",
          "@media (max-width: 599px)": {
            justifyContent: "flex-start",
            padding: "1rem",
          },
        }}
      >
        {services.map((service, index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                opacity: "0.99",
                justifyContent: "center",
                boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
                height: "300px",
                width: "100%",
                maxWidth: "600",
                cursor: "pointer",
                margin: "auto",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                },
              }}
              onClick={() => handleCardClick(service)}
            >
              <CardContent
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  overflow: "auto",
                  justifyContent: "center",
                  padding: ".5rem",
                  textAlign: "center",
                }}
              >
                {service.icon}
                <Typography
                  variant="h6"
                  sx={{
                    color: "rgb(46, 48, 145)",
                    fontWeight: "bold",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                    marginTop: "1rem",
                  }}
                >
                  {service.name}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {/* Modal for service details */}
      {selectedService && (
        <DynamicModal
          open={!!selectedService}
          onClose={handleCloseModal}
          title={selectedService.name}
          description={selectedService.description}
          images={selectedService.images}
          content={
            <div>
              <Typography>{selectedService.description}</Typography>
              <div style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
                {selectedService.images.map((image, idx) => (
                  <img
                    key={idx}
                    src={image.src} // This accesses the 'src' from the object
                    alt={`${selectedService.name} ${idx + 1}`}
                    style={{
                      width: "100px",
                      height: "auto",
                      borderRadius: "5px",
                    }}
                  />
                ))}
              </div>
            </div>
          }
        />
      )}
    </Box>
  );
};

export default Services;

// display: "flex",
// flexDirection: "column",
// alignItems: "center",
// justifyContent: "center",
