import React from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import styled from "styled-components";

// Define a styled button to match the style of OpenButton
const StyledButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  margin-bottom: 10px;
  background-color: rgb(46, 48, 145);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: rgb(46, 48, 145);
  }
`;

const PdfModal = ({ open, onClose, pdfUrl }) => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "downloaded-file.pdf"; // You can change the default file name
    link.click();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogContent
        style={{
          display: "flex", // Enable flexbox layout
          flexDirection: "column", // Ensure the content stacks vertically
          minHeight: "80vh", // Set a minimum height for the modal
        }}
      >
        {/* The iframe will now expand to fill the available space */}
        <iframe
          src={pdfUrl}
          width="100%"
          height="100%" // Take up all available height
          frameBorder="0"
          title="PDF Viewer"
          style={{
            flex: "1", // Allow iframe to expand and fill space
            minHeight: "400px", // Minimum height for the iframe (optional)
          }}
        />
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={handleDownload}>Download PDF</StyledButton>
        <StyledButton onClick={onClose}>Close</StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default PdfModal;
